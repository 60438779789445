import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint'
import React, { FC, useRef } from 'react'
import useTouchEvent, { Coords, StateUseTouchEvent } from '../../../hooks/useTouchEvent'
import { addCountlyEvent } from '../../../utils/stats'
import { Modal, Tabs } from '../../common'
import { Close } from '../../common/icons'
import Login from '../login/login'
import Recovery from '../recovery/recovery'
import styles from './forms-modal.module.scss'
import './forms-modal.ant.scss'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import { CONFERENCE_REGISTRATION_EXC } from '../../../constants/routes'
import Registration from '../registration/registration'

const { TabPane } = Tabs

// export type SelectForm = 'showLogin' | 'showRegistration' | ''

export interface Props<T> {
  tab: T
  selectTab: (tab: T) => void
}

const FormsModal: FC<Props<TabHeader>> = (props) => {
  const { tab, selectTab } = props
  const modalRef = useRef<HTMLDivElement | null>(null)
  const location = useLocation()
  const { lg: isDesktop } = useBreakpoint()
  const { t } = useTranslation()

  const exceptionConferenceRegistration = location.pathname === CONFERENCE_REGISTRATION_EXC

  const [setElement] = useTouchEvent(({ event, start, move, end }: StateUseTouchEvent<Coords>) => {
    if (event === 'touchstart') {
      if (!modalRef?.current) {
        modalRef.current = document.querySelector('.' + styles.modal)
      }
    }

    if (!modalRef?.current) return

    if (event === 'touchmove') {
      if (move.y > start.y) {
        modalRef.current.style.transform = `translateY(${move.y - start.y}px)`
      }
    }

    if (event === 'touchend' && end.y >= start.y) {
      modalRef.current = null
      selectTab('hidden')
    }
  })

  const handleChangeTab = (type = 'hidden'): void => {
    if (type === 'login' || type === 'registration' || type === 'hidden') {
      selectTab(type)
    }
  }

  const handleCancel = (type = 'hidden'): void => {
    if (type === 'registration') {
      addCountlyEvent(`Регистрация`, { Действие: 'Форма закрыта без регистрации' })
    } else if (type === 'login') {
      addCountlyEvent(`Авторизация`, { Действие: 'Форма закрыта без авторизации' })
    }

    selectTab('hidden')
  }

  return (
    <>
      <Modal
        visible={tab !== 'hidden'}
        footer={null}
        onCancel={() => handleCancel(tab)}
        className={styles.modal}
        keyboard={false}
        destroyOnClose={true}
        closable={isDesktop}
        closeIcon={<Close />}
        width={'inherit'}
      >
        <div className={styles.modalBody}>
          <div className={styles.swipeHolder} ref={(div) => setElement(div)} />
          {tab === 'login' && <h1 className={styles.modalH1}>{t("authorization")}</h1>}
          {tab === 'registration' && <h1 className={styles.modalH1}>{t("register")}</h1>}
          {tab === 'recovery' && <h1 className={styles.modalH1}>{t("passwordRecovery")}</h1>}

          {(tab === 'login' || tab === 'registration') && (
            <Tabs defaultActiveKey="login" activeKey={tab} onChange={handleChangeTab} >
              <TabPane tab={t("entrance")} key="login" className='loginHeader'>
                <Login tab={tab} selectTab={selectTab} />
              </TabPane>
              {exceptionConferenceRegistration && 
                <TabPane className='registration' tab={t("registration")} key="registration">
                  <Registration tab={tab} selectTab={selectTab} />
                </TabPane>
              }
            </Tabs>
          )}
          {tab === 'recovery' && <Recovery selectTab={selectTab} />}
        </div>
      </Modal>
    </>
  )
}

export default FormsModal
