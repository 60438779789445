import React from 'react'

const ConferenceProgram: React.FC<DefaultPropsIcon> = (props) => {
    const { size = 24, onClick, className, color = '#818C99' } = props
    return (
        <span className={['anticon', 'anticon-setting', className || ''].join(' ')} onClick={onClick}>
      <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M17 2C19.2091 2 21 3.79086 21 6V18C21 20.2091 19.2091 22 17 22H7C4.79086 22 3 20.2091 3 18V6C3 3.79086 4.79086 2 7 2H17ZM17 4H7C5.89543 4 5 4.89543 5 6V18C5 19.1046 5.89543 20 7 20H17C18.1046 20 19 19.1046 19 18V6C19 4.89543 18.1046 4 17 4ZM16 10C16.5523 10 17 10.4477 17 11V17C17 17.5523 16.5523 18 16 18H8C7.44772 18 7 17.5523 7 17V11C7 10.4477 7.44772 10 8 10H16ZM12 6C12.5523 6 13 6.44772 13 7C13 7.55228 12.5523 8 12 8H8C7.44772 8 7 7.55228 7 7C7 6.44772 7.44772 6 8 6H12Z"
            fill={color}/>
       </svg>
    </span>
    )
}

export default ConferenceProgram