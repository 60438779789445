import { Grid, Spin } from 'antd'
import React, { FC, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Link as RouterLink, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import useIsSticky from '../../../hooks/useIsSticky'
import { RootState } from '../../../store/store'
import { addCountlyEvent } from '../../../utils/stats'
import { AdminIcon, Burger } from '../../common/icons'
import { Button, Dropdown, Link } from '../../common/index'
import FormsModal from '../../forms/forms-modal/forms-modal'
// import GlobalSearch from '../../global-search/global-search'
import BurgerMenu from './burger-menu/burger-menu'
import styles from './header.module.scss'
import TopProfile from './top-profile/top-profile'
import { ADMIN_REGISTRATION, CONFERENCE_REGISTRATION_EXC, EXHIBITION_REGISTRATION } from '../../../constants/routes'
import LogoMNTK from '../../common/icons/logo/logo-mntk'
import './header.ant.scss'
import LogoFirstNPP from '../../common/icons/logo/logoFirstNPP'
import LogoFull from '../../common/icons/logo/logo-full'
import LogoFullEn from '../../common/icons/logo/logo-full-en'
import LogoMNTKEn from '../../common/icons/logo/logo-mntk-en'
import LogoFirstNPPEn from '../../common/icons/logo/logoFirstNPPEn'

interface HeaderProps {
  onScrollHeader: (val: boolean) => void
}

const Header: FC<HeaderProps> = (props) => {
  const { onScrollHeader } = props
  const loading = useSelector((state: RootState) => state.account.loading)
  const user = useSelector((state: RootState) => state.account.user)
  const [tab, setTab] = useState<TabHeader>('hidden')
  const selectTab = (tab: TabHeader): void => setTab(tab)
  const { sticked } = useIsSticky(false, styles.sticked, 0)
  const [showBurgerMenu, setShowBurgerMenu] = useState<boolean>(false)
  const location = useLocation()

  const { t, i18n } = useTranslation()

  // TODO: Зачем тут вешается event listener?
  useEffect(() => {
    document.body.addEventListener('click', () => {
      setShowBurgerMenu(false)
    })
  }, [])

  const onBurgerClick = (event: React.MouseEvent) => {
    event.nativeEvent.stopPropagation()
    setShowBurgerMenu(true)
  }

  useEffect(() => {
    if (location.pathname === ADMIN_REGISTRATION) {
      selectTab('registration')
    }

    if (location.pathname === EXHIBITION_REGISTRATION || location.pathname === CONFERENCE_REGISTRATION_EXC) {
      selectTab('registration')
    }
  }, [location])

  const handleDropdown = (key: string, value: string) => {
    localStorage.setItem('locale', value)
    i18n.changeLanguage(value)
    window.location.reload()
  }

  const breakpoints = Grid.useBreakpoint()
  const breakpointXl = breakpoints.xl

  useEffect(() => {
    onScrollHeader(sticked)
  }, [sticked, onScrollHeader])


  // breakpointXl ? stickedClassName : undefined

  // sticked ? styles.scroll : undefined

  return (
    <div className={[styles.header].join(' ')}>
      <div>
        <div className={styles.content}>
          <div className={[styles.logoWrapper].join(' ')}>
            <a 
              className={styles.firsLogo} 
              href={'https://rosenergoatom.ru/'} 
              rel="noopener noreferrer"
              target='_blank' 
              onClick={() => addCountlyEvent('Клик по логотипу КРЭА')}
            >
              {i18n.resolvedLanguage === "Eng" ? 
                <LogoFullEn width={breakpoints.xs ? 121 : undefined} height={breakpoints.xs ? 38 : undefined} /> : 
                <LogoFull width={breakpoints.xs ? 121 : undefined} height={breakpoints.xs ? 38 : undefined} />
              }
            </a>
            <a
              className={styles.secondLogo}
              href={'https://www.gidropress.podolsk.ru/'}
              target='_blank'
              rel="noopener noreferrer"
              onClick={() => addCountlyEvent('Клик по логотипу Гидропресс')}
            >
              {i18n.resolvedLanguage === "Eng" ? 
                <LogoMNTKEn width={breakpoints.xs ? 103 : undefined} height={breakpoints.xs ? 38 : undefined} /> : 
                <LogoMNTK width={breakpoints.xs ? 103 : undefined} height={breakpoints.xs ? 38 : undefined} />
              }
            </a>
            <RouterLink
              className={styles.thirdLogo}
              to={'/'}
              onClick={() => addCountlyEvent('Клик по логотипу 70 лет')}
            >
              {i18n.resolvedLanguage === "Eng" ? 
                <LogoFirstNPPEn width={breakpoints.xs ? 58 : undefined} height={breakpoints.xs ? 38 : undefined} /> : 
                <LogoFirstNPP width={breakpoints.xs ? 58 : undefined} height={breakpoints.xs ? 38 : undefined} />
              }
            </RouterLink>
          </div>
          {!loading && (
            <div className={styles.authBtnWrapper}>
              {breakpointXl && (
                <>
                  {/* <div className={styles.icon} onClick={() => addCountlyEvent('Переход в поиск на портале')}>
                    <GlobalSearch />
                  </div> */}
                  {user?.adminRoute && (
                    <Link to={user.adminRoute} className={styles.icon} external>
                      <AdminIcon size={40} />
                    </Link>
                  )}
                </>
              )}
              <Dropdown type="link" trigger={['click']} title={i18n.resolvedLanguage} onItemClick={handleDropdown}>
                <Dropdown.Option key={1} value="Ru">
                  Ru
                </Dropdown.Option>
                <Dropdown.Option key={2} value="Eng">
                  Eng
                </Dropdown.Option>
              </Dropdown>
              {!user && (
                <>
                  <Button
                    type={'primary'}
                    size={'small'}
                    onClick={() => {
                      selectTab('login')
                      addCountlyEvent(`Авторизация`, { Действие: 'Форма открыта' })
                    }}
                  >
                    {t('entrance')}
                  </Button>
                  {/*<Button*/}
                  {/*  type={'link'}*/}
                  {/*  size={'small'}*/}
                  {/*  onClick={() => {*/}
                  {/*    selectTab('registration')*/}
                  {/*    addCountlyEvent(`Регистрация`, { Действие: 'Форма открыта' })*/}
                  {/*  }}*/}
                  {/*>*/}
                  {/*  {t('registration')}*/}
                  {/*</Button>*/}
                </>
              )}
              {breakpointXl && user && <TopProfile />}
            </div>
          )}
          {breakpointXl && !sticked && loading && (
            <div className={styles.box_spin}>
              <Spin />
            </div>
          )}
          <div className={styles.mobileView}>
            {/* {!breakpointXl && (
              <div className={styles.icon} onClick={() => addCountlyEvent('Переход в поиск на портале')}>
                <GlobalSearch />
              </div>
            )} */}
            <Button type={'ghost'} onClick={onBurgerClick}>
              <Burger />
            </Button>
          </div>
        </div>
        <FormsModal tab={tab} selectTab={selectTab} />

        <BurgerMenu show={showBurgerMenu} selectTab={selectTab} onClose={() => setShowBurgerMenu(false)} />
      </div>
    </div>
  )
}

export default Header
