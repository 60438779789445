import { Menu as AntMenu } from 'antd'
import React, { FC } from 'react'
import { useLocation } from 'react-router-dom'
import * as routes from '../../../constants/routes'
import { addCountlyEvent } from '../../../utils/stats'
import {Contact, House, Picture, Conference, Exhibition, ConferenceMaterials, BarChart, ConferenceProgram} from '../../common/icons'
import Item from './item/item'
import './menu.ant.scss'
import Sections from '../../common/icons/sections'
import useInvisibleScroll from '../../survey/hooks/invisible-scoll'
import CommitteeContacts from '../../common/icons/committee-contacts'
import Applications from '../../common/icons/applications'
import { useSelector } from 'react-redux'
import { RootState } from '../../../store/store'
import SectionApplications from '../../common/icons/section-applications'
import ExhibitionApplications from '../../common/icons/exibition-applications'
import { useTranslation } from 'react-i18next'
import ManualMailing from '../../common/icons/manual-mailing'
import Broadcast from '../../common/icons/broadcast'

const Menu: FC = () => {
  const location = useLocation()
  const rootLocation = /^\/[^/]+/g.exec(location.pathname)
  const user = useSelector((state: RootState) => state.account.user)
  const { t } = useTranslation()

  const isAdminMNTK = user?.role === "mntk_admin"
  const exhibition_admin = user?.role === "exhibition_admin"
  const conference_admin = user?.role === "conference_admin"


  useInvisibleScroll()
  const path =
    (rootLocation &&
      (routes.SURVEYS_ADMIN.includes(rootLocation[0]) && rootLocation[0] !== routes.SURVEYS
        ? '/' + location.pathname.split('/')[1] + '/' + location.pathname.split('/')[2]
        : rootLocation[0])) ||
    '/'

  return (
    <AntMenu mode="inline" forceSubMenuRender className={'custom-menu dy-scrollbar-off'} defaultSelectedKeys={['/']} selectedKeys={[path]}>
      <Item route={routes.MAIN} title={t("main")} icon={<House />} key={1} />
      <Item route={routes.CONFERENCE} title={t("conference")} icon={<Conference />} key={2} />
      <Item route={routes.EXHIBITION} title={t("exhibition")} icon={<Exhibition />} key={3} />
      <Item
        route={routes.CONFERENCE_MATERIALS}
        title={t("conferenceMaterials")}
        icon={<ConferenceMaterials />}
        key={4}
      />
      {user &&
      <Item
          route={routes.PRESENTATION}
          title={t("conferenceProgram")}
          icon={<ConferenceProgram />}
          key={5}
      />}
      <Item route={routes.BROADCAST} title={t("broadcast")} icon={<Broadcast />} key={6} />
      <Item route={routes.SECTIONS} title={t("sections")} icon={<Sections />} key={7} />
      <Item route={routes.GALLERY} title={t("gallery")} icon={<Picture />} key={8} />
      <Item route={routes.COMMITTEE_CONTACTS} title={t("committeeContacts")} icon={<CommitteeContacts />} key={9} />
      <Item
        route={routes.CONTACTS}
        title={t("writeToUs")}
        icon={<Contact />}
        key={10}
        onClick={() => addCountlyEvent('Обратная связь', { Действие: 'Форма открыта', Источник: 'Меню' })}
      />

      {(isAdminMNTK || conference_admin || exhibition_admin) && 
        <AntMenu.Item key={99} className={'item-line-full'} />
      }
      {isAdminMNTK && 
        <Item route={routes.CONSIDERATION_APPLICATIONS} title={t("registration")} icon={<Applications />} key={11} />
      }

      {(isAdminMNTK || conference_admin) && 
        <Item route={routes.CONFERENCE_APPLICATIONS} title={t("sectionApplications")} icon={<SectionApplications />} key={12} />
      }

      {(isAdminMNTK || exhibition_admin) && 
        <Item route={routes.EXHIBITION_APPLICATIONS} title={t("exhibitionApplications")} icon={<ExhibitionApplications />} key={13} />
      }

      {(isAdminMNTK || conference_admin) &&
        <Item route={routes.STATISTICS} className='stroke' title={'Статистика'} icon={<BarChart />} key={14} />
      }

      {(isAdminMNTK || conference_admin || exhibition_admin) &&
        <Item route={routes.MANUAL_MAILING} className='stroke' title={t("manualMailing")} icon={<ManualMailing />} key={15} />
      }


    </AntMenu>
  )
}

export default Menu
