import axios, { AxiosResponse } from 'axios'
import * as Models from './model/models'
import { TokenRefreshRequest, applyAuthTokenInterceptor, setAuthTokens, clearAuthTokens, isLoggedIn, getAccessToken } from 'axios-jwt'
// import { CompetenceDocumentDirectoryWithParent } from './model/models'
import {
  ArrayResultsType,
  CurrentAttemptsType,
  CurrentDataDetailResultType,
  CurrentDataDetailType,
  CurrentTimerType,
  EditGroup,
  EditSurvey,
  ParticipantsAttemptsAnswersType,
  ParticipantsPayloadType,
  QuestionList,
  QuestionsList,
  ResultTest,
  SectionFull,
  SectionGroup,
  SectionType,
  Statuses,
  SurveyAdminType,
  TestQuestionType,
  TestsDataType,
  TimerStateType,
} from '../components/survey/types'
import { PaginatedResponse } from '../store/slices/surveyAdminSlice'
import { QuestionEditApi } from '../components/survey/survey-questions/map/question-edit-map'
import { VotingListItemApi } from '../components/voting/maps/to-votings-list-map'
import { CurrentVotingApi } from '../components/voting/maps/to-current-voting-map'
import { VoteResult } from '../components/voting/types'
import { ResultVotingApi } from '../components/voting/maps/to-result-voting-map'
import { ApiSection } from '../store/slices/sectionSlice'
import { ParticipantsSurveyType } from '../components/survey/common/participants-list/participants-list'
import { ApiEditGroup } from '../store/slices/groupSlice'
import { QuestionAdminFetchType } from '../store/slices/questionsAdminSlice'
import { FetchUserSyrveyType } from '../store/slices/surveySlice'
import i18next from '../i18n'

const currentLanguage = i18next.resolvedLanguage === 'Ru' ? 'ru' : i18next.resolvedLanguage === 'Eng' ? 'en' : 'ru'

const instance = axios.create({
  // eslint-disable-next-line no-undef
  baseURL: process.env.REACT_APP_ENDPOINT,
})

// tokens endpoint
const refreshTokenEndpoint = `${process.env.REACT_APP_ENDPOINT}/${currentLanguage}/api/jwt/refresh/`
const accessTokenEndpoint = `${process.env.REACT_APP_ENDPOINT}/${currentLanguage}/api/jwt/create/`

// define token refresh function
const requestRefresh: TokenRefreshRequest = async (refreshToken: string): Promise<string> => {
  // perform refresh
  const response = await axios.post(refreshTokenEndpoint, { refresh: refreshToken })
  return response.data.access
}

const authTokenInterceptorConfig = {
  header: 'Authorization',
  headerPrefix: 'Bearer ',
  requestRefresh,
};

// For working authorization when download files
(function () {
  const authToken = getAccessToken()
  authToken === null ?
    axios.defaults.headers.common.Authorization = null :
    axios.defaults.headers.common.Authorization = `Bearer ${authToken}`
})()

// add interceptor to instance
applyAuthTokenInterceptor(instance, authTokenInterceptorConfig)

/** API method prefixes:
 *  get... - method GET
 *  create... - method POST
 *  set... - method PUT
 *  delete... - method DELETE
 */

const COMPETENCES = `/${currentLanguage}/api/competences`
const REGISTER = `/${currentLanguage}/api/register`
const NEWS = `/${currentLanguage}/api/news`
const USERS = `/${currentLanguage}/api/users`
const GALLERY = `/gallery`
const ALBUMS = `/albums`
const MEDIA = `/${currentLanguage}/media`
const EVENTS = `/${currentLanguage}/api/events`
const CONTACTS = `/${currentLanguage}/api/contacts`
const FEEDBACK = `/${currentLanguage}/api/feedback`
const DOCUMENTS = `/${currentLanguage}/api/documents`
const DIRECTORY = `/${currentLanguage}/api/directory`
const DIRECTORY_POST = `/${currentLanguage}/api/directory-post`
const DIRECTORY_UPDATE_NAME = `/${currentLanguage}/api/directory-update-name`
const DIRECTORY_UPDATE_PATH = `/${currentLanguage}/api/directory-update-path`
const DIRECTORY_DELETE = `/${currentLanguage}/api/directory-delete`
const FILE_POST = `/${currentLanguage}/api/file-post`
const FILE_UPDATE_FOLDER = `/${currentLanguage}/api/file-update-folder`
const FILE_DELETE = `/${currentLanguage}/api/file-delete`
const FORUM = `/${currentLanguage}/api/forum`
const GLOBAL_SEARCH = `/${currentLanguage}/api/search`
const CHAMPIONSHIPS = `/${currentLanguage}/api/championship`
const PARTICIPANTS = `/${currentLanguage}/api/championship/participants`
const PARTICIPANTS_CHANGE_STATUS = `/${currentLanguage}/api/championship/admin/change-state`
const FAQ_URI = `/${currentLanguage}/api/faq`
const FORUM_SEARCH = `/${currentLanguage}/api/forum/search`
const COMPANIES = `/${currentLanguage}/api/companies`
const SURVEY_TIMER = `/${currentLanguage}/api/pass-test`
const SURVEY_PERSONAL = `/${currentLanguage}/api/personal-profile`
const SURVEY_TEST = `/${currentLanguage}/api/test`
const SURVEY_QUESTIONS = `/${currentLanguage}/api/test-questions`
const SURVEY_PASSED = `/${currentLanguage}/api/pass-test-answers`
const SURVEY_SECTIONS = `/${currentLanguage}/api/section`
const VOTING = `/${currentLanguage}/api/poll`
const SURVEY_QUESTIONS_EDIT = `/${currentLanguage}/api/question`
const GROUPS = `/${currentLanguage}/api/group`
const QUESTION_FILE = `/${currentLanguage}/api/xlsx/parse-file`
const QUESTION_LIST = `/${currentLanguage}/api/list-questions`
const SURVEY_RESULTS = `/${currentLanguage}/api/results`
const SKILLS_PASSPORT = `/${currentLanguage}/api/skills-passport`
const SECTIONS = `/${currentLanguage}/api/sections`
const APPLICATIONS_MNTK = `/${currentLanguage}/api/users/application`
const APPLICATIONS_ADMIN = `/${currentLanguage}/api/users/profiles`
const CITIZENSHIP = `/${currentLanguage}/api/citizenships/`
const ACADEMIC_DEGREES = `/${currentLanguage}/api/academic-degrees/`
const USER_DOCUMENTS = `/${currentLanguage}/api/documents/`
const MANUAL_MAILING = `/${currentLanguage}/api/manual_mailing/`
const APPLICATIONS_DOCUMENTS_ZIP = `/${currentLanguage}/api/documents/zip/`
const USER_APPLICATIONS_EXPORT = `/${currentLanguage}/api/users/application/export/`
const USER_APPLICATIONS_CREATED_EXPORT = `/${currentLanguage}/api/users/profiles/export/`
const CONFERENCE_USER_EXPORT = `/${currentLanguage}/api/users/profiles/conference/export/`
const EXHIBITION_USER_EXPORT = `/${currentLanguage}/api/users/profiles/exhibition/export/`
const STATISTICS = `/${currentLanguage}/api/sections/statistic/`
const STATISTICS_EXPORT = `/${currentLanguage}/api/sections/statistic/export/`


const skillsPassport = {
  saveUserChampionshipRating: (
    userId: number,
    championshipId: number,
    pointsByModule: number[],
  ): Promise<AxiosResponse> =>
    instance.put(`${SKILLS_PASSPORT}/${userId}/${championshipId}/`, { pointsByModule: pointsByModule }),
}

const mailing = {
  sendMails: (payload: Models.ManualMailingMsg): Promise<AxiosResponse> => 
    instance.post(`${MANUAL_MAILING}`, payload),
}


const userDocks = {
  addUserDocks: (payload: { document: File; type: Models.TypeEnum }): Promise<AxiosResponse> => {
    const data = new FormData()
    data.append('document', payload.document)
    data.append('type', payload.type)

    return instance.post(`${USER_DOCUMENTS}`, data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
  },
  deleteFile: async (id: number): Promise<void> => instance.delete(`${USER_DOCUMENTS}${id}`),
  changeStatus: async (payload: { id: number; requestData?: Models.DocumentApproveRequest }): Promise<void> =>
    instance.put(`${USER_DOCUMENTS}${payload.id}/`, payload?.requestData),
}

const citizenship = {
  getAll: async (): Promise<AxiosResponse<Array<Models.Citizenship>>> => instance.get(`${CITIZENSHIP}`),
}

const academicDegrees = {
  getAll: async (): Promise<AxiosResponse<Array<Models.AcademicDegree>>> => instance.get(`${ACADEMIC_DEGREES}`),
}

const sectionsMNTK = {
  getAllSections: async (): Promise<AxiosResponse<Array<Models.Section>>> => instance.get(`${SECTIONS}`),
}

const applications = {
  getMNTKApplications: async (payload?: {
    applicationStatus?: string
    role?: Models.RoleEnum
    section?: number
    lecture_type?: string
    company?: number
    status?: string
    companiesType?: "foreign" | "russian"
    citizenship?: number
    academicDegree?: boolean
  }): Promise<AxiosResponse<Array<Models.RegistrationApplication>>> => {
    return instance.get(
      `${APPLICATIONS_MNTK}${
        payload
          ? '?' +
            `${payload.applicationStatus ? `application_status=${payload.applicationStatus}&` : ''}` +
            `${payload.role ? `role=${payload.role}&` : ''}` +
            `${payload.section ? `section=${payload.section}&` : ''}` +
            `${payload.lecture_type ? `lecture_type=${payload.lecture_type}&` : ''}` +
            `${payload.company ? `company=${payload.company}&` : ''}` +
            `${payload.companiesType ? `company_type=${payload.companiesType}&` : ''}` +
            `${payload.citizenship ? `citizenship=${payload.citizenship}&` : ''}` +
            `${payload.academicDegree ? `documents=${payload.academicDegree}&` : ''}` +
            `${payload.status ? `status=${payload.status}&` : ''}`
          : ''
      }`,
    )
  },
  updateMNTKApplication: async ({
    id,
    approveStatus,
    status,
    comment,
  }: {
    id: string
    approveStatus?: string
    status?: string
    comment?: string
  }): Promise<void> => instance.put(`${APPLICATIONS_MNTK}/${id}/`, { applicationStatus: approveStatus, status: status, comment }),
  getAdminsApplications: async (payload?: {
    approveStatus?: string
    role?: Models.RoleEnum
    section?: number
    lecture_type?: string
    company?: number
    status?: string
    companiesType?: "foreign" | "russian"
    citizenship?: number
    reportUploaded?: boolean
  }): Promise<AxiosResponse<Array<Models.UserWS>>> => {
    return instance.get(
      `${APPLICATIONS_ADMIN}${
        payload
          ? '?' +
            `${payload.approveStatus ? `approve_status=${payload.approveStatus}&` : ''}` +
            `${payload.role ? `role=${payload.role}&` : ''}` +
            `${typeof payload?.section === 'number' ? `section=${payload.section}&` : ''}` +
            `${payload.lecture_type ? `lecture_type=${payload.lecture_type}&` : ''}` +
            `${payload.company ? `company=${payload.company}&` : ''}` +
            `${payload.companiesType ? `company_type=${payload.companiesType}&` : ''}` +
            `${payload.citizenship ? `citizenship=${payload.citizenship}&` : ''}` +
            `${payload.reportUploaded ? `documents=${payload.reportUploaded}&` : ''}` +
            `${payload.status ? `status=${payload.status}&` : ''}`
          : ''
      }`,
    )
  },
  updateAdminsApplication: async ({
    id,
    approveStatus,
    status,
    comment,
  }: {
    id: string
    approveStatus?: string
    status?: string
    comment?: string
  }): Promise<void> => instance.put(`${APPLICATIONS_ADMIN}/${id}/`, { approveStatus, status, comment }),
  documentsZip: async (payload: { ids?: React.Key[], type?: string }): Promise<AxiosResponse<BlobPart>> =>
    instance.get(`${APPLICATIONS_DOCUMENTS_ZIP}${
      payload
        ? '?' +
        `${payload.ids && payload?.ids?.length>0 ? `ids=${payload.ids.join(",")}&` : ''}` +
        `${payload.type ? `type=${payload.type}` : ''}`
        : ''
    }`,{responseType: 'blob'},
  ),
  userApplicationsExport: async (payload: { 
    ids?: React.Key[]
    applicationStatus?: string
    role?: Models.RoleEnum
    section?: number
    lecture_type?: string
    company?: number
    status?: string
    companiesType?: "foreign" | "russian"
    citizenship?: number
    reportUploaded?: boolean
  }): Promise<AxiosResponse<BlobPart>> =>
    instance.get(`${USER_APPLICATIONS_EXPORT}${
      payload
        ? '?' +
        `${payload?.ids && payload?.ids?.length>0 ? `ids=${payload.ids.join(",")}&` : ''}` +
        `${payload.applicationStatus ? `application_status=${payload.applicationStatus}&` : ''}` +
        `${payload.role ? `role=${payload.role}&` : ''}` +
        `${typeof payload?.section === 'number' ? `section=${payload.section}&` : ''}` +
        `${payload.lecture_type ? `lecture_type=${payload.lecture_type}&` : ''}` +
        `${payload.company ? `company=${payload.company}&` : ''}` +
        `${payload.companiesType ? `company_type=${payload.companiesType}&` : ''}` +
        `${payload.citizenship ? `citizenship=${payload.citizenship}&` : ''}` +
        `${payload.reportUploaded ? `documents=${payload.reportUploaded}&` : ''}` +
        `${payload.status ? `status=${payload.status}&` : ''}`
        : ''
    }`,{responseType: 'blob'},
  ),
  userApplicationsCreatedExport: async (payload: { 
    ids?: React.Key[]
    approveStatus?: string
    role?: Models.RoleEnum
    section?: number
    lecture_type?: string
    company?: number
    status?: string
    companiesType?: "foreign" | "russian"
    citizenship?: number
    reportUploaded?: boolean
  }): Promise<AxiosResponse<BlobPart>> =>
    instance.get(`${USER_APPLICATIONS_CREATED_EXPORT}${
      payload
        ? '?' +
        `${payload?.ids && payload?.ids?.length>0 ? `ids=${payload.ids.join(",")}&` : ''}` +
        `${payload.approveStatus ? `approve_status=${payload.approveStatus}&` : ''}` +
        `${payload.role ? `role=${payload.role}&` : ''}` +
        `${typeof payload?.section === 'number' ? `section=${payload.section}&` : ''}` +
        `${payload.lecture_type ? `lecture_type=${payload.lecture_type}&` : ''}` +
        `${payload.company ? `company=${payload.company}&` : ''}` +
        `${payload.companiesType ? `company_type=${payload.companiesType}&` : ''}` +
        `${payload.citizenship ? `citizenship=${payload.citizenship}&` : ''}` +
        `${payload.reportUploaded ? `documents=${payload.reportUploaded}&` : ''}` +
        `${payload.status ? `status=${payload.status}&` : ''}`
        : ''
    }`,{responseType: 'blob'},
  ),
  conferenceUserExport: async (payload: { 
    ids?: React.Key[]
    approveStatus?: string
    role?: Models.RoleEnum
    section?: number
    lecture_type?: string
    company?: number
    status?: string
    companiesType?: "foreign" | "russian"
    citizenship?: number
    reportUploaded?: boolean
  }): Promise<AxiosResponse<BlobPart>> =>
    instance.get(`${CONFERENCE_USER_EXPORT}${
      payload
        ? '?' +
        `${payload?.ids && payload?.ids?.length>0 ? `ids=${payload.ids.join(",")}&` : ''}` +
        `${payload.approveStatus ? `approve_status=${payload.approveStatus}&` : ''}` +
        `${payload.role ? `role=${payload.role}&` : ''}` +
        `${typeof payload?.section === 'number' ? `section=${payload.section}&` : ''}` +
        `${payload.lecture_type ? `lecture_type=${payload.lecture_type}&` : ''}` +
        `${payload.company ? `company=${payload.company}&` : ''}` +
        `${payload.companiesType ? `company_type=${payload.companiesType}&` : ''}` +
        `${payload.citizenship ? `citizenship=${payload.citizenship}&` : ''}` +
        `${payload.reportUploaded ? `documents=${payload.reportUploaded}&` : ''}` +
        `${payload.status ? `status=${payload.status}&` : ''}`
        : ''
    }`, {responseType: 'blob'},
  ),
  exhibitionUserExport: async (payload: { 
    ids?: React.Key[]
    approveStatus?: string
    role?: Models.RoleEnum
    section?: number
    lecture_type?: string
    company?: number
    status?: string
    companiesType?: "foreign" | "russian"
    citizenship?: number
    reportUploaded?: boolean
  }): Promise<AxiosResponse<BlobPart>> =>
    instance.get(`${EXHIBITION_USER_EXPORT}${
      payload
        ? '?' +
        `${payload?.ids && payload?.ids?.length>0 ? `ids=${payload.ids.join(",")}&` : ''}` +
        `${payload.approveStatus ? `approve_status=${payload.approveStatus}&` : ''}` +
        `${payload.role ? `role=${payload.role}&` : ''}` +
        `${typeof payload?.section === 'number' ? `section=${payload.section}&` : ''}` +
        `${payload.lecture_type ? `lecture_type=${payload.lecture_type}&` : ''}` +
        `${payload.company ? `company=${payload.company}&` : ''}` +
        `${payload.companiesType ? `company_type=${payload.companiesType}&` : ''}` +
        `${payload.citizenship ? `citizenship=${payload.citizenship}&` : ''}` +
        `${payload.reportUploaded ? `documents=${payload.reportUploaded}&` : ''}` +
        `${payload.status ? `status=${payload.status}&` : ''}`
        : ''
    }`,{responseType: 'blob'},
  ),
  getStatistics: async (): Promise<AxiosResponse<Array<Models.SectionStatistic>>> => instance.get(`${STATISTICS}`),
  downloadStatistics: async (): Promise<AxiosResponse<BlobPart>> => instance.get(`${STATISTICS_EXPORT}`,{responseType: 'blob'}),
}

const account = {
  login: async (payload: Models.CustomTokenObtainPair): Promise<void> => {
    const response: AuthResponse = (await axios.post(accessTokenEndpoint, payload)).data
    // save tokens to storage
    setAuthTokens({ accessToken: response.access, refreshToken: response.refresh })
  },
  logout: async (): Promise<void> => {
    try {
      await instance.delete(`${USERS}/logout/`)
    } finally {
      clearAuthTokens()
    }
  },
  clearTokens: (): void => {
    clearAuthTokens()
  },
  getProfile: async (): Promise<AxiosResponse | undefined> => {
    //  if there are saved tokens in the browser
    if (isLoggedIn()) {
      return await instance.get(`${USERS}/me/`)
    }
  },
  resetPassword: (payload: Models.SendEmailReset): Promise<AxiosResponse> =>
    instance.post(`${USERS}/reset_password/`, payload),
  passwordConfirm: (payload: Models.PasswordResetConfirm): Promise<AxiosResponse> =>
    instance.post(`${USERS}/reset_password_confirm/`, payload),
}

const competences = {
  getCompetences: <T>(): Promise<AxiosResponse<T>> => instance.get(`${COMPETENCES}/`),
  getCompetence: (id: string): Promise<AxiosResponse<Models.CompetenceDetail>> => instance.get(`${COMPETENCES}/${id}/`),
  getCompetenceDocs: (
    payload: GetCompetenceDocsPayload,
  ): Promise<AxiosResponse<Models.PaginatedCompetenceDocumentList>> =>
    instance.get(`${COMPETENCES}/${payload.id}/documents/?limit=${payload.limit}&offset=${payload.offset}`),
  getDivisionExperts: (id: string): Promise<AxiosResponse<Array<Models.UserWSBase>>> =>
    instance.get(`${COMPETENCES}/${id}/division_experts/`),
  getCompetenceStatistic: (id: string): Promise<AxiosResponse<Array<Models.CompetenceVisitStatistics>>> =>
    instance.get(`${COMPETENCES}/${id}/statistic`),
  addCompetenceStatistic: (id: string): Promise<AxiosResponse> => instance.post(`${COMPETENCES}/${id}/statistic/`),
  checkNewDocuments: (id: string) => instance.get(`${COMPETENCES}/${id}/documents-status`),
}

const register = {
  registrationApplication: (payload: Models.RegistrationApplicationPost): Promise<AxiosResponse> =>
    instance.post(REGISTER + '/', payload),
}

const main = {
  getMainPageSlider: (): Promise<AxiosResponse<Models.MainPageSlider[]>> =>
    instance.get(`/${currentLanguage}/api/mainPageSlider/`),
  getPage: (slug: string): Promise<AxiosResponse<Models.Page>> =>
    instance.get(`/${currentLanguage}/api/pages/${slug}/`),
}

const news = {
  getNews: (payload: GetNewsPayload): Promise<AxiosResponse<Models.PaginatedNewsList>> =>
    instance.get(
      `${NEWS}/?limit=${payload.limit || 20}&offset=${payload.offset || 0}${
        payload.year ? '&year=' + payload.year : ''
      }${payload.publishOnMainPage ? '&publish_on_main_page=' + payload.publishOnMainPage : ''}`,
    ),
  getNewsYears: (): Promise<AxiosResponse<Models.NewsYears>> => instance.get(`${NEWS}/years/`),
  getNewsDetail: (id: number): Promise<AxiosResponse<Models.NewsDetail>> => instance.get(`${NEWS}/${id}/`),
}

const events = {
  getEvents: (payload: GetEventsPayload): Promise<AxiosResponse<Models.PaginatedEventList>> =>
    instance.get(
      `${EVENTS}/?limit=${payload.limit || 20}&offset=${payload.offset || 0}${
        payload.periods ? '&periods=' + payload.periods : ''
      }${payload.bannerOnMainPage ? '&banner_on_main_page=' + payload.bannerOnMainPage : ''}`,
    ),
  getEventDetail: (id: number): Promise<AxiosResponse<Models.EventDetail>> => instance.get(`${EVENTS}/${id}/`),
}

const gallery = {
  getAlbums: (payload: GetAlbumsPayload): Promise<AxiosResponse<Models.PaginatedAlbumList>> =>
    instance.get(
      `/${currentLanguage}/api${GALLERY}${ALBUMS}/?limit=${payload.limit || 7}&offset=${payload.offset || 0}`,
    ),
  getAlbum: (payload: GetAlbumPayload): Promise<AxiosResponse<Models.AlbumDetail>> =>
    instance.get(`/${currentLanguage}/api${GALLERY}${ALBUMS}/${payload.id}/`),
  getMedia: (payload: GetMediaPayload): Promise<AxiosResponse<Models.PaginatedAlbumMediaList>> =>
    instance.get(
      `/${currentLanguage}/api${GALLERY}${ALBUMS}/${payload.id}${MEDIA}/?limit=${payload.limit}&offset=${payload.offset}`,
    ),
}

const contacts = {
  getContacts: (payload: Paginated): Promise<AxiosResponse<Models.PaginatedContactList>> =>
    instance.get(`${CONTACTS}/?limit=${payload.limit || 10}&offset=${payload.offset || 0}`),
}

const feedback = {
  postFeedback: (payload: Models.FeedbackMsg): Promise<AxiosResponse> => instance.post(`${FEEDBACK}/`, payload),
  postDivisionExpertFeedback: (payload: Models.DivisionExpertFeedbackMsg): Promise<AxiosResponse> =>
    instance.post(`${FEEDBACK}/division_expert/`, payload),
}

const directory = {
  getDirectoryContent: (payload: GetDirectoryContentPayload): Promise<AxiosResponse<DirectoryContentResponse>> =>
    instance.get(
      `${DIRECTORY}/?competence_id=${payload.competenceId}&dir_id=${payload.dirId || ''}&limit=${
        payload.limit || ''
      }&offset=${payload.offset || ''}`,
    ),
  getDirectory: (payload: string): Promise<AxiosResponse<Models.CompetenceDocumentDirectoryWithParent>> =>
    instance.get(`${DIRECTORY}/${payload}/`),
  postDocument: (document: { file: File; parent: number }): Promise<AxiosResponse<DocumerntPostResponse>> => {
    const data = new FormData()
    data.append('document', document.file)
    data.append('parent', document.parent.toString())

    return instance.post(`${FILE_POST}/`, data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
  },
  movingDocument: ({ id, parent }: MovingRequest): Promise<AxiosResponse<MovingResponse>> => {
    return instance.put(`${FILE_UPDATE_FOLDER}/${id}/`, { parent: parent })
  },
  deleteDocument: (docId: string): Promise<void> => instance.delete(`${FILE_DELETE}/${docId}/`),
  postDirectory: (dirData: DirectoryPostResponse): Promise<AxiosResponse<DirectoryPostResponse>> =>
    instance.post(`${DIRECTORY_POST}/`, dirData),
  renameDirectory: (dirId: string, dirName: string): Promise<AxiosResponse<DirectoryPostResponse>> =>
    instance.put(`${DIRECTORY_UPDATE_NAME}/${dirId}/`, { name: dirName }),
  movingDirectory: ({ id, parent }: MovingRequest): Promise<AxiosResponse<MovingResponse>> => {
    return instance.put(`${DIRECTORY_UPDATE_PATH}/${id}/`, { parent: parent })
  },
  deleteDirectory: (dirId: string): Promise<void> => instance.delete(`${DIRECTORY_DELETE}/${dirId}/`),
  getCompetenceDocsStatistic: (id: string): Promise<AxiosResponse<Array<Models.DocumentDownloadStatistics>>> =>
    instance.get(`${DOCUMENTS}/${id}/statistic`),
  addCompetenceDocsStatistic: (id: string): Promise<AxiosResponse> => instance.post(`${DOCUMENTS}/${id}/statistic/`),
}

const captcha = {
  getCaptcha: (): Promise<AxiosResponse<Captcha>> => instance.post(`/api/captcha/`),
}

const forum = {
  getTopics: (payload: GetTopicsPayload): Promise<AxiosResponse<Models.PaginatedForumTopicReadList>> =>
    instance.get(
      `${FORUM}/topic/?competence=${payload.competence}&limit=${payload.limit || 10}&offset=${payload.offset || 0}`,
    ),
}

const forumTopic = {
  getTopic: (id: number): Promise<AxiosResponse<Models.ForumTopicReadDetail>> => instance.get(`${FORUM}/topic/${id}/`),
  postTopic: (payload: Partial<Models.ForumTopicCreate>): Promise<AxiosResponse> =>
    instance.post(`${FORUM}/topic/`, payload),
  getTopicPollOptionsResults: (topicId: number): Promise<AxiosResponse<Models.PaginatedTopicPollOptionsResultsList>> =>
    instance.get(`${FORUM}/topic/${topicId}/poll/options-results/?limit=1000&offset=0`),
  getTopicPollCustomOptionsResults: (
    topicId: number,
  ): Promise<AxiosResponse<Models.PaginatedTopicPollCustomOptionsResultsList>> =>
    instance.get(`${FORUM}/topic/${topicId}/poll/custom-results/?limit=1000&offset=0`),
  postTopicPoll: (payload: Models.ForumPollTopicCreate): Promise<AxiosResponse<Models.ForumPollTopicCreate>> =>
    instance.post(`${FORUM}/topic/poll/`, payload),
  postTopicPollVote: (
    topicId: number,
    payload: Omit<Models.TopicPollVote, 'totalVotesCount'>,
  ): Promise<AxiosResponse<Models.TopicPollVote>> => instance.post(`${FORUM}/topic/${topicId}/poll/vote/`, payload),
  putTopic: (
    topicId: number,
    payload: Models.TopicUpdateByAuthor | Models.TopicUpdateByModerator,
  ): Promise<AxiosResponse<Models.TopicUpdateByAuthor | Models.TopicUpdateByModerator>> =>
    instance.put(`${FORUM}/topic/${topicId}/`, payload),
  deleteTopic: (id: number): Promise<AxiosResponse> => instance.delete(`${FORUM}/topic/${id}/`),
  getInfo: (id: number): Promise<AxiosResponse<Models.ForumInfo>> => instance.get(`${FORUM}/info/?competence=${id}`),
}

const forumComments = {
  getComments: (payload: GetForumCommentsPayload): Promise<AxiosResponse<Models.PaginatedForumCommentReadList>> =>
    instance.get(`${FORUM}/comment/?limit=${payload.limit || 10}&offset=${payload.offset || 0}&topic=${payload.topic}`),
  postComment: (payload: Partial<Models.ForumCommentCreate>): Promise<AxiosResponse<Models.ForumCommentRead>> =>
    instance.post(`${FORUM}/comment/`, payload),
  putComment: (
    payload: { id: number } & Models.ForumCommentUpdate,
  ): Promise<AxiosResponse<Models.ForumCommentUpdate>> =>
    instance.put(`${FORUM}/comment/${payload.id}/`, { message: payload.message }),
  deleteComment: (id: number): Promise<AxiosResponse> => instance.delete(`${FORUM}/comment/${id}/`),
  postAttachments: (payload: AttachmentsPayload) => {
    const { uploadedFile, commentId } = payload
    const data = new FormData()
    data.append('comment', commentId.toString())
    uploadedFile && data.append('uploadedFile', uploadedFile)
    return instance.post(`${FORUM}/attachments/`, data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
  },
  deleteAttachment: (id: number) => instance.delete(`${FORUM}/attachments/${id}/`),
}

const forumGroup = {
  getGroups: (payload: GetForumGroupsPayload): Promise<AxiosResponse<Models.PaginatedForumGroupList>> =>
    instance.get(
      `${FORUM}/group/?competence=${payload.competence}&limit=${payload.limit || 20}&offset=${payload.offset || 0}`,
    ),
}

const forumSearch = {
  getSearch: (payload: ForumSearchPayload): Promise<AxiosResponse<Models.PaginatedForumSearchResultList>> => {
    return instance.get(
      `${FORUM_SEARCH}/?competence=${payload.competence}&expr=${payload.expr}&limit=${payload.limit || 20}&offset=${
        payload.offset || 0
      }`,
    )
  },
}

const globalSearch = {
  getSearch: (payload: GlobalSearchPayload): Promise<AxiosResponse<Models.SearchGroup[]>> =>
    instance.get(`${GLOBAL_SEARCH}/?expr=${encodeURIComponent(payload.expr)}`),
  getSearchByGroup: (payload: GlobalSearchByGroupPayload): Promise<AxiosResponse<Models.PaginatedSearchGroupList>> =>
    instance.get(
      `${GLOBAL_SEARCH}/${payload.modelName}_/?expr=${encodeURIComponent(payload.expr)}&limit=${
        payload?.limit || 50
      }&offset=${payload?.offset || 0}`,
    ),
}
//: Promise<AxiosResponse<Models.PaginatedChampionshipTypeList>>
const championships = {
  getChampionshipsTypes: (payload: GetChampionshipsTypesPayload) =>
    instance.get(`${CHAMPIONSHIPS}/type/?limit=${payload.limit || 16}&offset=${payload.offset || 0}`),
  getChampionshipsSubType: (payload: string): Promise<AxiosResponse<Models.ChampionshipSubTypeWithType>> =>
    instance.get(`${CHAMPIONSHIPS}/subtypes/${payload}/`),
  getChampionshipsList: (
    payload: GetChampionshipsListPayload,
  ): Promise<AxiosResponse<Models.PaginatedChampionshipList>> =>
    instance.get(
      `${CHAMPIONSHIPS}/?limit=${payload.limit || 16}&offset=${payload.offset || 0}&periods=${
        payload.periods || ''
      }&sub_type=${payload.subType}`,
    ),
  getChampionshipDetail: (payload: { id: number }): Promise<AxiosResponse<Models.ChampionshipDetail>> =>
    instance.get(`${CHAMPIONSHIPS}/${payload.id}/`),
  getChampionshipDocs: (
    payload: GetChampionshipDocsPayload,
  ): Promise<AxiosResponse<Models.PaginatedChampionshipDocumentList>> =>
    instance.get(`${CHAMPIONSHIPS}/${payload.id}/docs/?limit=${payload.limit}&offset=${payload.offset || 0}`),
  championshipRegistration: (payload: ChampionshipRegistrationPayload): Promise<AxiosResponse> => {
    const { championshipId, participantId, ...rest } = payload
    return instance.post(`${CHAMPIONSHIPS}/register/${championshipId}/${participantId}/`, rest)
  },
  getLastChampionship: (): Promise<AxiosResponse<Models.Championship>> => instance.get(`${CHAMPIONSHIPS}/last/`),
}

const participants = {
  getParticipants: (payload: GetParticipantsPayload): Promise<AxiosResponse<Models.ChampionshipsParticipants[]>> =>
    instance.get(`${PARTICIPANTS}/${payload.championshipId}/${payload.status}/`),
  searchParticipants: (
    payload: SearchParticipantsPayload,
  ): Promise<AxiosResponse<Models.ChampionshipsParticipants[]>> =>
    instance.get(`${PARTICIPANTS}/${payload.championshipId}/${payload.status}/${payload.search}/`),
  changeStatusParticipants: (payload: ChangeStatusParticipantsPayload): Promise<AxiosResponse> =>
    instance.put(`${PARTICIPANTS_CHANGE_STATUS}/${payload.id}/${payload.status}/`),
  exportParticipants: (idChamp: string): Promise<AxiosResponse<File>> =>
    instance.get(`/api/export-users/${idChamp}`, { responseType: 'blob' }),
}

const userProfile = {
  get: (payload: GetUserProfilePayload): Promise<AxiosResponse<Models.UserWSDetail>> => {
    return instance.get(`${USERS}/${payload.id}/profile/`)
  },
  put: (id: number, payload: Models.UserWSDetail): Promise<AxiosResponse<Models.UserWSDetail>> => {
    return instance.put(`${USERS}/${id}/profile/`, payload)
  },
  postSetPassword: (payload: Models.SetPassword): Promise<AxiosResponse> =>
    instance.post(`${USERS}/set_password/`, payload),
  putCreateAvatar: (payload: PutUserProfilePayloadChangeAvatar): Promise<AxiosResponse> => {
    const { avatar, id } = payload
    const data = new FormData()
    if (avatar) {
      avatar && data.append('avatar', avatar)
      return instance.put(`${USERS}/${id}/profile/`, data, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
    } else {
      return instance.put(`${USERS}/${id}/profile/`, { avatar: null })
    }
  },
  getPublicProfile: (payload: GetUserPublicProfilePayload): Promise<AxiosResponse<Models.UserPublic>> =>
    instance.get(`${USERS}/${payload.id}/public/`),
  putNotifySetting: () => instance.put(`${USERS}/email-notify/`),
}

const FAQ = {
  get: (): Promise<AxiosResponse<Models.FaqQuestion[]>> => instance.get(`${FAQ_URI}/`),
}

const companies = {
  get: (payload: CompanyType): Promise<AxiosResponse<Models.Company[]>> =>
    instance.get(`${COMPANIES}${payload ? `/?company_type=${payload}` : ''}`),
}

const sections = {
  get: (payload: Paginated): Promise<AxiosResponse<SectionType[]>> =>
    instance.get(`${SURVEY_SECTIONS}/?limit=${payload.limit}&offset=${payload.offset}`),
  getFull: (id: number): Promise<AxiosResponse<SectionFull>> => instance.get(`${SURVEY_SECTIONS}/${id}/`),
  update: (section: ApiSection): Promise<AxiosResponse<void>> =>
    instance.put(`${SURVEY_SECTIONS}/${section.id}/`, section),
  create: (section: ApiSection): Promise<AxiosResponse<SectionFull>> => instance.post(`${SURVEY_SECTIONS}/`, section),
  deleteSection: (id: number): Promise<AxiosResponse<SectionFull>> => instance.delete(`${SURVEY_SECTIONS}/${id}/`),
}

const survey = {
  getTimer: (userId: number, attemptId: number): Promise<AxiosResponse<CurrentTimerType>> =>
    instance.get(`${SURVEY_TIMER}/${userId}/${attemptId}/timer`),

  runTimer: (userId: number, attemptId: number): Promise<AxiosResponse<TimerStateType>> =>
    instance.put(`${SURVEY_TIMER}/run-timer/${userId}/${attemptId}`, { timerState: 2 }),

  stopTimer: (userId: number, attemptId: number): Promise<AxiosResponse<TimerStateType>> =>
    instance.put(`${SURVEY_TIMER}/stop-timer/${userId}/${attemptId}`, { timerState: 1 }),

  getTestsForUser: (payload: FetchUserSyrveyType): Promise<AxiosResponse<TestsDataType>> =>
    instance.get(`${SURVEY_PERSONAL}/tests/${payload.userId}/?status=${payload.status}`),

  getUserAttempts: (userId: number): Promise<AxiosResponse<CurrentAttemptsType>> =>
    instance.get(`${SURVEY_PERSONAL}/attempts-history/${userId}`),

  getQuestions: (surveyId: number): Promise<AxiosResponse<PaginatedResponse<TestQuestionType>>> =>
    instance.get(`${SURVEY_QUESTIONS}/${surveyId}/question/`),

  getAllQuestions: (): Promise<AxiosResponse<PaginatedResponse<TestQuestionType>>> =>
    instance.get(`${SURVEY_QUESTIONS_EDIT}/?limit=500000&offset=0`),
  getQuestionsBySection: (sectionId: number[]): Promise<AxiosResponse<QuestionEditApi[]>> =>
    instance.get(`${SURVEY_QUESTIONS_EDIT}/?section_id=${sectionId.join()}`),
  getQuestionsByCompetence: (competenceId: number[]): Promise<AxiosResponse<TestQuestionType[]>> =>
    instance.get(`${SURVEY_QUESTIONS_EDIT}/?competence_id=${competenceId.join()}`),

  getQuestionsEdit: (payload: QuestionAdminFetchType): Promise<AxiosResponse<PaginatedResponse<QuestionEditApi>>> =>
    instance.get(
      `${SURVEY_QUESTIONS_EDIT}/?limit=${payload.params.limit}&offset=${payload.params.offset}${
        payload.params.text && `&text=${payload.params.text}`
      }${
        payload.params.section_id && payload.params.section_id.length !== 0
          ? `&section_id=${payload.params.section_id.join()}`
          : ''
      }${
        payload.params.competences && payload.params.competences.length !== 0
          ? `&competence_id=${payload.params.competences.join()}`
          : ''
      }${payload.type && payload.type.length !== 0 ? `&type=${payload.type.join(`&type=`)}` : ''}`,
    ),
  deleteQuestionEdit: (payload: number): Promise<AxiosResponse> =>
    instance.delete(`${SURVEY_QUESTIONS_EDIT}/${payload}`),
  updateQuestionEdit: (payload: QuestionEditApi): Promise<AxiosResponse<QuestionEditApi>> =>
    instance.put(`${SURVEY_QUESTIONS_EDIT}/${payload.id}/`, payload),
  sendQuestionEdit: (payload: QuestionEditApi): Promise<AxiosResponse<QuestionEditApi>> =>
    instance.post(`${SURVEY_QUESTIONS_EDIT}/`, payload),

  getQuestionEdit: (id: number): Promise<AxiosResponse<QuestionEditApi>> =>
    instance.get(`${SURVEY_QUESTIONS_EDIT}/${id}/`),

  getTestDetail: (userId: number, surveyId: number): Promise<AxiosResponse<CurrentDataDetailType>> =>
    instance.get(`${SURVEY_TEST}/${userId}/${surveyId}/show/`),

  getAdminList: (payload: Paginated, status: Statuses): Promise<AxiosResponse<SurveyAdminType[]>> =>
    instance.get(`${SURVEY_TEST}/?status=${status}&limit=${payload.limit}&offset=${payload.offset}`),
  getEditSurvey: (id: number): Promise<AxiosResponse<EditSurvey>> => instance.get(`${SURVEY_TEST}/${id}/`),
  sendEditSurvey: (payload: EditSurvey): Promise<AxiosResponse<EditSurvey>> =>
    instance.post(`${SURVEY_TEST}/`, payload),
  updateEditSurvey: (payload: EditSurvey): Promise<AxiosResponse> =>
    instance.put(`${SURVEY_TEST}/${payload.id}/`, payload),
  deleteEditSurvey: (payload: number): Promise<AxiosResponse> => instance.delete(`${SURVEY_TEST}/${payload}/`),

  getUserAnswers: (
    userId: number,
    surveyId: number,
    attemptId: number,
  ): Promise<AxiosResponse<ParticipantsAttemptsAnswersType>> =>
    instance.get(`${SURVEY_PASSED}/${userId}/${surveyId}/${attemptId}/participant-answer/`),
  sendTestAnswers: (resultObj: ArrayResultsType, userId: number, attemptId: number): Promise<AxiosResponse<void>> =>
    instance.post(`${SURVEY_PASSED}/${userId}/${attemptId}/participant-answer`, resultObj),
}

const results = {
  getFull: (id: number): Promise<AxiosResponse<CurrentDataDetailResultType>> =>
    instance.get(`${SURVEY_RESULTS}/${id}/`),
  get: (
    offset: number,
    size: number,
    query?: string,
    id?: number,
  ): Promise<AxiosResponse<PaginatedResponse<ResultTest>>> =>
    instance.get(
      `${SURVEY_RESULTS}/?offset=${offset}&limit=${size}${id && !isNaN(id) ? `&test_id=${id}` : ''}${
        query ? `&test=${query}&company=${query}` : ''
      }`,
    ),
  exportFile: (id: number): Promise<AxiosResponse<File>> => {
    if (id) return instance.get(`${SURVEY_RESULTS}/${id}/export/`, { responseType: 'blob' })
    else return instance.get(`${SURVEY_RESULTS}/export/`, { responseType: 'blob' })
  },
}

const questions = {
  importFile: (payload: File): Promise<AxiosResponse> => {
    const data = new FormData()
    data.append('file', payload)
    return instance.post(`${QUESTION_FILE}`, data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
  },
  uploadImage: (payload: IdFile): Promise<AxiosResponse<void>> => {
    const data = new FormData()
    data.append('file', payload.file)
    return instance.put(`${SURVEY_QUESTIONS_EDIT}/${payload.id}/upload-file/`, data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
  },
  exportFile: (): Promise<AxiosResponse<File>> => instance.get('/api/question/export/', { responseType: 'blob' }),
}

const groups = {
  get: (payload: Paginated): Promise<AxiosResponse<SectionGroup>> =>
    instance.get(`${GROUPS}/?limit=${payload.limit}&offset=${payload.offset}`),
  getDetail: (payload: number): Promise<AxiosResponse<EditGroup>> => instance.get(`${GROUPS}/${payload}/`),
  deleteGroup: (id: number): Promise<AxiosResponse<void>> => instance.delete(`${GROUPS}/${id}/`),
  getParticipants: (payload: ParticipantsPayloadType = {}): Promise<AxiosResponse<ParticipantsSurveyType[]>> =>
    instance.get(`${USERS}/profiles/`, {
      params: {
        championship_id: payload.championshipId,
        championship_points: payload.championshipPoints,
        competence_id:
          Array.isArray(payload.competenceId) && payload.competenceId.length
            ? payload.competenceId.join()
            : payload.competenceId,
        limit: payload.limit,
        offset: payload.offset,
      },
    }),
  update: (payload: ApiEditGroup): Promise<AxiosResponse<EditGroup>> =>
    instance.put(`${GROUPS}/${payload.id}/`, payload),
  create: (payload: ApiEditGroup): Promise<AxiosResponse<EditGroup>> => instance.post(`${GROUPS}/`, payload),
}

const questionList = {
  getQuestionsByList: (listId: number): Promise<AxiosResponse<QuestionsList>> =>
    instance.get(`${QUESTION_LIST}/${listId}/`),
  getQuestionsLists: (): Promise<AxiosResponse<QuestionList[]>> => instance.get(`${QUESTION_LIST}/`),
  exportFile: (id: number): Promise<AxiosResponse<File>> =>
    instance.get(`${QUESTION_LIST}/${id}/export`, { responseType: 'blob' }),
  sendList: (list: QuestionsList): Promise<AxiosResponse<void>> => instance.post(`${QUESTION_LIST}/`, list),
  updateList: (list: QuestionsList): Promise<AxiosResponse<void>> => instance.put(`${QUESTION_LIST}/${list.id}/`, list),
}

const voting = {
  getActive: (paginated: Paginated): Promise<AxiosResponse<PaginatedResponse<VotingListItemApi>>> =>
    instance.get(`${VOTING}/active?limit=${paginated.limit}&offset=${paginated.offset}`),
  getLastActive: (): Promise<AxiosResponse<VotingListItemApi>> => instance.get(`${VOTING}/active/last`),
  getEnded: (paginated: Paginated): Promise<AxiosResponse<PaginatedResponse<VotingListItemApi>>> =>
    instance.get(`${VOTING}/completed?limit=${paginated.limit}&offset=${paginated.offset}`),
  getAll: (paginated: Paginated): Promise<AxiosResponse<PaginatedResponse<VotingListItemApi>>> =>
    instance.get(`${VOTING}?limit=${paginated.limit}&offset=${paginated.offset}`),
  get: (id: number): Promise<AxiosResponse<CurrentVotingApi>> => instance.get(`${VOTING}/${id}`),
  sendAnswer: (answers: VoteResult): Promise<AxiosResponse<void>> =>
    instance.post(`${VOTING}/${answers.poll}/poll-vote/`, answers),
  getResult: (id: number): Promise<AxiosResponse<ResultVotingApi[]>> =>
    instance.get(`${VOTING}/${id}/results/?limit=0&offset=0`),
  getResultExport: (id: number): Promise<AxiosResponse<File>> =>
    instance.get(`${VOTING}/${id}/results/export/`, { responseType: 'blob' }),
}

export default {
  mailing,
  userDocks,
  citizenship,
  academicDegrees,
  sectionsMNTK,
  applications,
  skillsPassport,
  competences,
  register,
  main,
  news,
  account,
  gallery,
  events,
  contacts,
  feedback,
  directory,
  captcha,
  forum,
  forumTopic,
  forumComments,
  forumGroup,
  forumSearch,
  globalSearch,
  championships,
  participants,
  userProfile,
  FAQ,
  companies,
  survey,
  sections,
  voting,
  groups,
  questions,
  results,
  questionList,
}
